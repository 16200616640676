import React from "react"
import Grid from "../../components/grid/Grid"
import Card from "../../components/card/Card"
import Date from "../../components/date/Date"
import Layout from "../../components/layout/Layout"
import Markdown from "../../components/markdown/Markdown"
import css from "./Campaign.module.css"

export default ({ pageContext: { url, node, data } }) => {
  return (
    <Layout
      type="HMF"
      className={css.campaign}
      slug={`${url}/${node.slug}`}
      title={node.nombre}
    >
      <section className={css.header}>
        <div className="container">
          <h1 className={css.title}>{node.nombre}</h1>
          <img src={node.portada?.file.url} alt="img"></img>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="text-column">
            <Markdown className={css.contain}>
              {node.cuerpo.childMarkdownRemark.html}
            </Markdown>
          </div>
          {node.documentos && (
            <>
              <p>si es q hay documentos, indicar aqui:</p>
              <Grid>
                {node.documentos.map((document, i) => (
                  <a href={document.archivo.file.url} key={i}>
                    <h2>{document.nombre}</h2>
                    <p>{document.descripcion.descripcion}</p>
                  </a>
                ))}
              </Grid>
            </>
          )}
        </div>
      </section>
      <section className="articles-related">
        <div className="container">
          <h2>Últimas artículos relacionados</h2>
          <Grid>
            {data.articles.slice(0, 4).map((item, i) => (
              <Card url={`/${item.slug}`} key={i}>
                <img src={item.portada?.file.url} alt="img"></img>
                <h3>{item.titulo}</h3>
                <Date date={item.fecha} />
                <p>{item.cuerpo.childMarkdownRemark.excerpt}</p>
              </Card>
            ))}
          </Grid>
        </div>
      </section>
    </Layout>
  )
}
